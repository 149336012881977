* {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.nav-link-icon {
  /* color: #0b0b0b; */
  color: #59595a;
}

.nav-btn:hover .nav-link-icon {
  color: #000;
}
.nav-btn {
  margin-bottom: 0.5rem !important;
}
.nav-btn:hover {
  background-color: #e0eff6 !important;
  border-radius: 10px;
  color: #1c1c1d;
  font-weight: bold !important;
}

.nav-btn.active {
  background-color: #e0eff6 !important;
  border-radius: 10px;
  color: #1c1c1d;
  font-weight: bold !important;
}
.AppBar {
  background-color: #f7f7f7;
  padding: 0.5rem;
  color: #000;
  border: none;
}

/* .MuiAppBar-root {
  box-shadow: 10px !important;
  border: 0px !important;
  background-color: #f8f7fc !important;
} */

.MuiTableCell-head {
  background: rgb(199, 221, 233) !important;
  /* background: #616161 !important; */
  /* font-size: 1.1rem !important; */
  padding: 1rem !important;
  font-weight: 500;
  /* color: white !important; */
}
thead .MuiTableCell-root:first-child {
  border-top-left-radius: 10px;
}

thead .MuiTableCell-root:last-child {
  border-top-right-radius: 10px;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #d7d7dc !important;
  border: none !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

/* sider shadow */
.MuiDrawer-paperAnchorDockedLeft {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: 0px 2px 10px -1px rgba(245, 153, 34, 0.2),
    0px 4px 5px 0px rgba(232, 167, 27, 0.14),
    0px 1px 10px 0px rgba(171, 233, 26, 0.12) !important;
}
.dashboard {
  color: #0c0c0c !important;

  text-decoration: none !important;
}
.nav-link {
  text-decoration: none;
  color: #000;
}

.dashboard:hover,
.user:hover {
  color: orangered !important;
  /* color: #000; */
  cursor: pointer !important;
}

.dob {
  color: #fff;
  border-color: #000;
}

.btn {
  color: #fff;
  border-color: #000;
}

.align {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.loading {
  color: black;
}
.exercise {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  background: #f7f7f7;
}
.btn-align {
  display: flex;
  justify-content: flex-end;
  margin: 3rem;
}

.btn-center {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.flex--3--cols {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 1rem 2rem; */
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem;
}
.first--cols-box {
  display: flex;
  gap: 8rem;
}
.first--cols {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-text-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.btn-user-update {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid--2--cols {
  display: grid;
  grid-template-columns: 300px 1fr;
  margin-top: 0;
  padding: 1rem;
  gap: 1rem;
}

.grid-item {
  grid-template-columns: 1fr 1fr;

  /* gap: 1rem; */
}
.grid-routine {
  grid-template-columns: 400px 400px;
  margin: 0 auto;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.grid-img {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
.description {
  grid-column: 1/-1;
}
.description-text {
  height: 400px;

  overflow-y: scroll;
}
.create-btn {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
.btn_end {
  display: flex;
  grid-row: 1/-1;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.container {
  background-color: white;
}
.update-day {
  display: flex;
  justify-content: space-between;
  color: black;
  background-color: #cecece;
  padding: 0 2;
  width: 50vw;
  height: 70px;
  margin: 0 auto;
  align-items: center;
  margin-top: 1rem;
}
.text-container {
  width: 50vw;
  height: 82vh;
  margin: 0 auto;
}
.text-item {
  display: flex;
  justify-content: center;
}
.text-box {
  flex-direction: column;
  display: block;
}
.text-day {
  width: 45vw;
  height: 400px;
  overflow: scroll;
}
.progress:focus {
  background: none !important;
  /* color: orangered !important; */
}
.paragraph {
  height: 20rem;
}
